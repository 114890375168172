import { useEffect, useState } from 'react'
import { RateQuestion } from './components/question_types/RateQuestion'
import { Input } from './components/question_types/Input'
import { Checkbox } from './components/question_types/Checkbox'
import { useTranslation } from 'react-i18next'
import { NewAreaRate } from 'ui/pages/poll/components/question_types/NewAreaRate'
import { YesNo } from 'ui/pages/poll/components/question_types/YesNo'

const Question = ({
  questions,
  specialData,
  onSectionCompleted,
  onPreviousSection,
  lastQuestionId,
  onAnswerChange,
  answers,
  saveForm,
  isFirstSection,
  questionsIds,
  formId,
  hashContinue,
  isLastSection,
}) => {
  const { t } = useTranslation('global')
  const [questionId, setQuestionId] = useState(lastQuestionId)
  const [sectionCompleted, setSectionCompleted] = useState(false)
  const [showSpecialQuestions, setShowSpecialQuestions] = useState(false)
  const [isEmpty, setIsEmpty] = useState(false)
  const [mandatoryComment, setMandatoryComment] = useState(false)
  const [isFirstStep, setIsFirstStep] = useState(true)
  const [isLastStep, setIsLastStep] = useState(false)
  const [progress, setProgress] = useState(hashContinue ? 45 : 0)
  const [direction, setDirection] = useState(1)

  useEffect(() => {
    setQuestionId(lastQuestionId)
  }, [lastQuestionId])

  const nextQuestion = (event) => {
    event.preventDefault()
    const savedAnswer = answers.find(answer => answer.question_id === questions[questionId].id)
    const invalidFirstCustomer = questions[questionId].id === 644 && ((!savedAnswer.value && !savedAnswer.comment) || (savedAnswer.value === 1 && !savedAnswer.comment) || (!savedAnswer.value && savedAnswer.comment))
    const invalidAnswer = savedAnswer && (savedAnswer.value === undefined || savedAnswer.value === null)
    if (!isLastStep) {
      setDirection(1)
      // Set mandatory question when field is true and only for rate questions
      if (questions[questionId].mandatory
        && invalidFirstCustomer) {
        if (!savedAnswer.value) {
          setIsEmpty(true)
        } else if (!savedAnswer.comment) {
          setIsEmpty(false)
          setMandatoryComment(true)
        }
      } else if (questions[questionId].mandatory && invalidAnswer) {
        setIsEmpty(true)
      } else {
        setIsEmpty(false)
        setMandatoryComment(false)
        if (questionId < questions.length - 1) {
          setQuestionId(questionId + 1)
        } else {
          setSectionCompleted(true)
        }
        handleProgress(questionId, false)

        // After clicking on next, if it was the first step, now it is not anymore
        if (isFirstStep && isFirstSection) {
          setIsFirstStep(false)
        } else if (isLastSection && questionId + 1 === questions.length - 1) {
          setIsLastStep(true)
        }
      }
    } else {
      if (questions[questionId].mandatory && questions[questionId].question_type === 2 && (!savedAnswer.value && !savedAnswer.comment)) {
        setIsEmpty(true)
      } else {
        saveForm(answers)
        onSectionCompleted()
      }
    }
  }

  const previousQuestion = (event) => {
    event.preventDefault()
    setIsEmpty(false)
    setDirection(-1)
    if (questionId > 0) {
      setQuestionId(questionId - 1)
    } else {
      onPreviousSection()
    }
    handleProgress(questionId, true)

    // Check if when we set questionId it is 0, then check if we need to reset first step
    if (isFirstSection && questionId - 1 === 0) {
      setIsFirstStep(true)
    }

    if (isLastStep) setIsLastStep(false)
  }

  const finishForm = (event) => {
    event.preventDefault()
    setIsLastStep(true)
    saveForm(answers)
  }

  const handleProgress = (questionId, isBack) => {
    // Questions ids are the ids of all the questions (except the area questions) and they are in order shown
    const totalProgress = questionsIds.length - 1

    const actualQuestion = questionsIds.indexOf(questionsIds.find(questionsId => questionsId === questions[questionId].id))
    const actualPosition = isBack ? actualQuestion - 1 : actualQuestion + 1

    // For customer form (id 20 in pro and id 34 in dev), set hardcoded progress
    if (formId === 20 || formId === 34) {
      const customProgress = hashContinue ? [45, 65, 80, 90, 95, 100] : [0, 45, 65, 80, 90, 95, 100]
      const progress = customProgress[actualPosition]
      setProgress(progress)
    } else {
      // Progress computed as the actual id, with which we can access the index in questions ids and know the position of actual question
      const progress = actualPosition / totalProgress * 100
      setProgress(progress)
    }

  }

  const variants = {
    enter: (direction) => ({
      y: direction > 0 ? '100%' : '-100%',
      opacity: 0
    }),
    center: {
      y: 0,
      opacity: 1
    },
    exit: (direction) => ({
      y: direction < 0 ? '100%' : '-100%',
      opacity: 0
    })

  }

  useEffect(() => {
    if (sectionCompleted) {
      onSectionCompleted()
      setQuestionId(0)
      setSectionCompleted(false)
    }
  }, [sectionCompleted, onSectionCompleted])

  useEffect(() => {
    document.documentElement.style.setProperty('--progressWidth', `${progress.toString()}%`)
  }, [progress])

  return (
    <div key={questions[questionId].id} className="w-full flex-initial flex-grow flex flex-col page-transition">
      <form className="flex-initial flex-grow flex flex-col">
        <div
          className="flex-grow flex flex-col"
        >
          {questions[questionId].question_type === 2 &&
            <RateQuestion onAnswerChange={onAnswerChange}
                          savedAnswers={answers}
                          question={questions[questionId]}
                          key={questions[questionId]}
            />
          }
          {questions[questionId].question_type === 12 && (questions[questionId].id === 1001 || questions[questionId].id === 706) &&
            <NewAreaRate
              question={questions[questionId]}
              areasData={specialData}
              onAnswerChange={onAnswerChange}
              savedAnswers={answers}
              key={questions[questionId]}
            />
            // <AreaRate onAnswerChange={onAnswerChange}
            //           savedAnswers={answers}
            //           question={questions[questionId]}
            //           key={questions[questionId]}
            //           showSpecialQuestions={showSpecialQuestions}
            //           areasData={areasData}
            //           showButtons={handleButtonsVisibility}
            //           onNextClick={nextQuestion}
            //           finishForm={finishForm}
            // />
          }
          {questions[questionId].question_type === 0 &&
            <Input onAnswerChange={onAnswerChange}
                   savedAnswers={answers}
                   question={questions[questionId]}
                   key={questions[questionId]}
            />
          }
          {questions[questionId].question_type === 7 &&
            <Checkbox onAnswerChange={onAnswerChange}
                      savedAnswers={answers}
                      question={questions[questionId]}
                      key={questions[questionId]}
            />}
          {
            // Yes no type
            questions[questionId].question_type === 4 &&
            <YesNo onAnswerChange={onAnswerChange}
                   savedAnswers={answers}
                   question={questions[questionId]}
                   secondaryQuestion={specialData}
            />
          }
        </div>

        {!showSpecialQuestions &&
          <div className="flex flex-col mb-10 small:mb-2 gap-2 mt-4">
            {(isEmpty || mandatoryComment) &&
              <p className="text-rose-600 text-center text-xs italic font-normal mb-2">
                {isEmpty ? t('fill_question') : t('fill_comment')}
              </p>}
            <button type="submit" className={`continue-btn ${isFirstStep ? 'mb-12' : ''}`} onClick={nextQuestion}>
              {isLastStep ? t('finish') : t('continue')}
            </button>
            {!isFirstStep && <button className="prev-btn" onClick={previousQuestion}>{t('back')}</button>}
            {!isFirstStep && !isLastStep && <button type="submit" onClick={finishForm} className="finish-btn">{t('finish_here')}</button>}
          </div>}
      </form>
    </div>
  )
}

export default Question
